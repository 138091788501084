.mobile_warning {
  display       : none;
  padding-bottom: 1rem;
  padding-left  : 1rem;
  padding-top   : 1rem;
  background    : blue;
  color         : white;
}


@media (min-width: 768px) {
  .mobile_warning {
    display: block;
  }

}