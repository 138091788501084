.download_container {
    width: 100%;

    .download_header {
        width: 100%;

        .download_header_img {
            width: 100%;
        }

    }

    .download_content {
        position: relative;
        width   : 100%;

        .download_logo {
            position              : absolute;
            top                   : -9rem;
            left                  : 50%;
            margin-left           : -4rem;
            width                 : 8rem;
            height                : 8rem;
            border-radius         : 20px;
            overflow: hidden;
            .download_logoImg{
                width: 8rem;
                height: 8rem;
            }
        }

        .download_content_title {
            text-align   : center;
            margin-top   : 5rem;
            font-size    : 1.4rem;
            font-weight  : bold;
            margin-bottom: 1rem;
        }

        .download_content_des {
            color        : #868686;
            font-size    : 1rem;
            padding      : 0 1rem;
            margin-bottom: 4rem;
            text-align: center;
        }

        .download_content_btn {
            text-align      : center;
            width           : 85%;
            height          : 3rem;
            line-height     : 3rem;
            background-color: #0497fd;
            margin          : 0 auto;
            color           : #fff;
            font-size       : 1.2rem;
            letter-spacing  : .3rem;
            border-radius: 2rem;
        }
    }


}